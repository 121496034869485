import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  POST_REQUEST_SIGNUP,
  POST_VALIDATE_OTP,
  REGENERATE_EMAIL_OTP,
  SIGNUP_REQUEST_GOOGLE,
  SIGNUP_REQUEST_MICROSOFT
} from "./type"
// actions
import {
  signupRequestSuccess,
  signupRequestFailure,
  googleSignupRequestSuccess,
  googleSignupRequestFailure,
  microsoftSignupRequestFailure,
  microsoftSignupRequestSuccess,
  validateOTPSuccess,
  validateOTPFailure,
  displayOTPModal
} from "./action"
import {
  loginRequestSuccess,
  setAccessToken,
  loginRequestFailure
} from "../../Login/redux/action"
import toast from "react-hot-toast"
import dayjs from "dayjs"
import Cookies from "js-cookie"

async function postSignupAPI(data) {
  const URL = `${BASE_URL}api/v1/signup/`
  const csrfToken = Cookies.get("csrftoken")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postSignupInfo({ data, history }) {
  try {
    const response = yield call(postSignupAPI, data)
    if (response?.data?.token) {
      let expiryTime
      if (localStorage.getItem("rememberMe")) {
        expiryTime = dayjs(new Date()).add(7, "d")
      } else {
        expiryTime = dayjs(new Date()).add(2, "h")
      }
      yield put(loginRequestSuccess({ ...response?.data, expiryTime }))
      localStorage.setItem("token", response.data.token)
      yield put(setAccessToken(response.data.token))
    }
    if (response?.data?.otp_sent) {
      toast.success(response?.data?.message, {
        id: "card fields"
      })
      yield put(signupRequestSuccess(response?.data))
      yield put(displayOTPModal(true))
    }
  } catch (e) {
    const { response } = e
    toast.error(response.data.error_message)
    yield put(signupRequestFailure(response?.data))
  }
}

async function googleSignupRequestAPI(data) {
  const URL = `${BASE_URL}api/v1/google/signup/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function microsoftSignupRequestAPI(data) {
  const URL = `${BASE_URL}api/v1/microsoft/signup/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* googleRequestSignup({ data, history }) {
  try {
    const response = yield call(googleSignupRequestAPI, data)
    // yield put(signupRequestSuccess(response?.data))
    yield put(googleSignupRequestSuccess(response?.data))
    // history.push('/auth/login')
    let expiryTime
    if (localStorage.getItem("rememberMe")) {
      expiryTime = dayjs(new Date()).add(7, "d")
    } else {
      expiryTime = dayjs(new Date()).add(2, "h")
    }
    yield put(loginRequestSuccess({ ...response?.data, expiryTime }))
    localStorage.setItem("token", response?.data.token)
    yield put(setAccessToken(response?.data.token))
    yield put(loginRequestFailure(false))
    toast.success("Sign Up Successfully", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    toast.error("User is already registered with this e-mail address")
    yield put(googleSignupRequestFailure(response?.data))
  }
}

function* microsoftRequestSignup({ data, history }) {
  try {
    const response = yield call(microsoftSignupRequestAPI, data)
    yield put(signupRequestSuccess(response?.data))
    yield put(microsoftSignupRequestSuccess(response?.data))
    let expiryTime
    if (localStorage.getItem("rememberMe")) {
      expiryTime = dayjs(new Date()).add(7, "d")
    } else {
      expiryTime = dayjs(new Date()).add(2, "h")
    }
    yield put(loginRequestSuccess({ ...response?.data, expiryTime }))
    localStorage.setItem("token", response?.data.token)
    yield put(setAccessToken(response?.data.token))
    yield put(loginRequestFailure(false))
    // history.push('/auth/login')
    toast.success("Sign Up Successfully", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    toast.error("User is already registered with this e-mail address")
    yield put(microsoftSignupRequestFailure(response?.data))
  }
}

async function postValidateOTP(data) {
  const URL = `${BASE_URL}api/v1/signup/verify_email_otp/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* validateOTPInfo({ data, history, loginFlag }) {
  try {
    const response = yield call(postValidateOTP, data)
    yield put(validateOTPSuccess(response?.data))
    // history.push('/auth/login')
    toast.success("OTP Verification Successful", {
      id: "card fields"
    })
    yield put(displayOTPModal(false))
    yield put(validateOTPFailure(false))
    if (loginFlag) {
      let expiryTime
      if (localStorage.getItem("rememberMe")) {
        expiryTime = dayjs(new Date()).add(7, "d")
      } else {
        expiryTime = dayjs(new Date()).add(2, "h")
      }
      yield put(loginRequestSuccess({ ...response?.data, expiryTime }))
      localStorage.setItem("token", response?.data.token)
      yield put(setAccessToken(response?.data.token))
    } else {
      history.push("/auth/login")
    }
  } catch (e) {
    const { response } = e
    yield put(validateOTPFailure(response?.data))
    toast.error(response?.data?.error_message, {
      id: "card fields"
    })
  }
}

async function signupRegenerateOTP(data) {
  const URL = `${BASE_URL}api/v1/signup/resend_email_verification_mail/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* regenerateSignupOTP({ data }) {
  try {
    yield call(signupRegenerateOTP, data)
    toast.success("OTP Resent", {
      id: "card fields"
    })
    yield put(signupRequestFailure(false))
  } catch (e) {
    // TODO Handle response error
    // eslint-disable-next-line
    const { response } = e
  }
}

export default all([
  takeLatest(POST_REQUEST_SIGNUP, postSignupInfo),
  takeLatest(SIGNUP_REQUEST_GOOGLE, googleRequestSignup),
  takeLatest(SIGNUP_REQUEST_MICROSOFT, microsoftRequestSignup),
  takeLatest(POST_VALIDATE_OTP, validateOTPInfo),
  takeLatest(REGENERATE_EMAIL_OTP, regenerateSignupOTP)
])
