import { connect } from "react-redux"
import React, { useCallback, useEffect, useState, useRef } from "react"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

import {
  getCandidateListRequest,
  getCandidateListSuccess,
  exportCandidateListRequest,
  getCandidateCountRequest,
  bulkCandidatesStatusUpdateRequest
} from "./redux/action"
import DataTable from "../../../components/DataTable"
import ExportModal from "../../../components/RecruiterModals/ExportModal"
import IconButton from "../../../components/IconButton"
import USearch from "../../../components/USearch"
import UCheckbox from "../../../components/UCheckbox"

import "./style.scss"
import { ReactComponent as ExportIcon } from "../../../assets/images/export-icon.svg"
import { getCurrencySymbol, currentUser } from "../../../utils/helper"
import AvatarBadge from "../../../components/AvatarBadge"
import { useHistory, useParams } from "react-router-dom"
import UProgressbar from "../../../components/UProgressbar"
import { jobStatus } from "../../../utils/constants"
import { firstLetterCapital, isArray } from "../../../utils/commonFunction"
import { DEFAULT_CURRENCY_CODE, NOT_AVAILABLE } from "../../../utils/constants"
import { getJobDataByIdRequest } from "../../CommonScreens/JobDetails/redux/action"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Popover from "@mui/material/Popover"

const ALL_CANDIDATES_KEY = "all-applications"

const CandidateList = props => {
  const {
    getCandidateListDispatch,
    candidateData,
    requesting,
    getCandidateListSuccessDispatch,
    getCandidateCount,
    candidateCount,
    exportRequesting,
    exportCandidateListDispatch,
    bulkCandidatesStatusUpdateDispatch,
    bulkUpdateRequesting,
    getJobData,
    jobDetails
  } = props
  const [showExportModal, setShowExportModal] = useState(false)
  const history = useHistory()
  const { jobId } = useParams()
  const [jobInformation, setJobInformation] = useState({})

  const pageInfo = useRef(window.history.state?.pageInfo ?? {})
  const { location } = history
  const listTypes = location.pathname.includes("all-applications")
    ? [...jobStatus]
    : [{ key: ALL_CANDIDATES_KEY, label: "All candidates" }, ...jobStatus]

  const setPageInfoFromQueryParams = pageInfo => {
    const parsedQueryParams = new URLSearchParams(location?.search)
    pageInfo.current = {
      page: parseInt(parsedQueryParams.get("page") ?? 1),
      limit: parseInt(parsedQueryParams.get("limit") ?? 10),
      sortBy: parsedQueryParams.get("sortBy"),
      sortDir: parsedQueryParams.get("sortDir") ?? "asc",
      applicationStatus:
        parsedQueryParams.get("applicationStatus") ?? "applied",
      searchTerm: parsedQueryParams.get("searchTerm")
    }
  }

  setPageInfoFromQueryParams(pageInfo)

  useEffect(() => {
    setPageInfoFromQueryParams(pageInfo)
  }, [location?.search])

  const updateQueryParamsInUrl = pageInfo => {
    const queryParams = new URLSearchParams(window.location.search)
    pageInfo.current.page
      ? queryParams.set("page", pageInfo.current.page)
      : queryParams.delete("page")
    pageInfo.current.limit
      ? queryParams.set("limit", pageInfo.current.limit)
      : queryParams.delete("limit")
    pageInfo.current.sortBy
      ? queryParams.set("sortBy", pageInfo.current.sortBy)
      : queryParams.delete("sortBy")
    pageInfo.current.sortDir
      ? queryParams.set("sortDir", pageInfo.current.sortDir)
      : queryParams.delete("sortDir")
    pageInfo.current.searchTerm
      ? queryParams.set("searchTerm", pageInfo.current.searchTerm)
      : queryParams.delete("searchTerm")
    pageInfo.current.applicationStatus
      ? queryParams.set("applicationStatus", pageInfo.current.applicationStatus)
      : queryParams.delete("applicationStatus")
    window.history.replaceState(null, null, "?" + queryParams.toString())
  }

  useEffect(() => {
    const shouldResetJobInformation = jobId !== jobInformation?._id
    if (shouldResetJobInformation) {
      setJobInformation({})
    }
    if (jobId) {
      getJobData({ jobId })
      setJobInformation(jobDetails)
    }
  }, [jobId])

  useEffect(() => {
    if (jobDetails?._id && jobDetails?._id === jobId) {
      setJobInformation(jobDetails)
    } else {
      setJobInformation({})
    }
  }, [jobDetails])

  const [listType, setListType] = useState(
    pageInfo.current.applicationStatus ?? "applied"
  )
  const [requestParams, setRequestParams] = useState({})
  const [searchParam, setSearchParam] = useState("")
  const [selectedCandidates, setSelectedCandidates] = useState({})
  const [selectedApplications, setSelectedApplications] = useState({})

  const handleTabClick = tab => () => setListType(tab)

  const goToCandidateProfile = applicationData => () => {
    const current_user = currentUser()?.user
    const userType = current_user?.user_type
    if (userType) {
      if (listType === ALL_CANDIDATES_KEY) {
        return (
          true &&
          history.push(
            `/${userType}/selected_profile/${applicationData.candidate_id}`,
            {
              applicationData,
              jobId: "",
              jobInformation: ""
            }
          )
        )
      }
      return (
        true &&
        history.push(
          `/${userType}/selected_profile/${applicationData.candidate_id}/${applicationData.id}`,
          {
            applicationData,
            jobId: jobId ?? jobInformation?.id,
            jobInformation: jobInformation,
            applicationId: ""
          }
        )
      )
    }
  }

  const goToJobDashboard = applicationData => () => {
    const current_user = currentUser()?.user
    const userType = current_user?.user_type
    if (userType) {
      return (
        true &&
        history.push(
          `/${userType}/candidates/${applicationData.job_listing_id}`,
          {
            applicationData,
            jobId: "",
            jobInformation: ""
          }
        )
      )
    }
  }

  const handleCheckBoxChange = e => {
    const { value, checked } = e.target

    let selectedList = { ...selectedCandidates }
    let selectedApplicationsList = { ...selectedApplications }
    if (value === "all") {
      if (checked) {
        selectedList["all"] = true
      } else {
        selectedList = {}
      }
      selectedApplicationsList = candidateData?.data?.reduce(
        (acc, item) => ({
          ...acc,
          [item?.id]: checked
        }),
        {}
      )
    } else {
      if (!checked) {
        if (selectedList.all) {
          delete selectedList.all
          selectedList = candidateData?.data?.reduce(
            (acc, item) => ({
              ...acc,
              [item?.candidate_id]: value === item?.candidate_id ? false : true
            }),
            {}
          )
          selectedApplicationsList = candidateData?.data?.reduce(
            (acc, item) => ({
              ...acc,
              [item?.id]: value === item?.id ? false : true
            }),
            {}
          )
        }
      }
      selectedList[value] = checked ? true : false
      selectedApplicationsList[value] = checked ? true : false
    }
    setSelectedCandidates(selectedList)
    setSelectedApplications(selectedApplicationsList)
  }

  const CandidatesListColumns = []
  if (!!jobId) {
    CandidatesListColumns.push({
      id: "multiselect",
      label: "",
      renderColumn: rowData => {
        return (
          <UCheckbox
            className="candidate-select"
            value={rowData?.id}
            onChange={handleCheckBoxChange}
            checked={
              selectedCandidates[rowData?.id] || selectedCandidates.all
                ? true
                : false
            }
          />
        )
      },
      renderHeader: () => {
        return (
          <UCheckbox
            className="candidate-select select-all"
            value="all"
            onChange={handleCheckBoxChange}
            checked={selectedCandidates?.all ? true : false}
          />
        )
      }
    })
  }

  CandidatesListColumns.push(
    ...[
      {
        id: "candidate_name",
        label: "Candidate",
        width: "40%",
        sortable: true,
        renderColumn: rowData => {
          const name = rowData["candidate_name"] || rowData["candidate_email"]
          return (
            <div
              className="candidate-name"
              onClick={goToCandidateProfile(rowData)}
            >
              <AvatarBadge name={name} />
              <span>{name}</span>
            </div>
          )
        }
      },
      {
        id: "job_title",
        label: "Job Title",
        width: "40%",
        sortable: true,
        renderColumn: rowData => {
          const jobTitle = rowData.job_title
          return (
            <div className="candidate-name" onClick={goToJobDashboard(rowData)}>
              <span>{jobTitle}</span>
            </div>
          )
        }
      },
      {
        id: "match_level",
        label: "Match Level",
        width: "40%",
        sortable: true,
        hidden: jobInformation?.id ? false : true,
        renderColumn: rowData => {
          return <UProgressbar progressValue={rowData["match_level"] || 0} />
        }
      },
      {
        id: "candidate_type",
        label: "Type",
        width: "5%",
        sortable: true,
        renderColumn: rowData => (
          <span>
            {firstLetterCapital(rowData["candidate_type"]) || "General"}
          </span>
        )
      },
      {
        id: "country",
        label: "Country",
        width: "20%",
        sortable: true,
        renderColumn: rowData => {
          return rowData["country"] ? (
            <span>{rowData["country"]}</span>
          ) : (
            NOT_AVAILABLE
          )
        }
      },
      {
        id: "currency",
        label: "Currency",
        width: "3%",
        sortable: true,
        renderColumn: rowData => {
          return rowData["currency"] ? (
            <span>{getCurrencySymbol(rowData["currency"])}</span>
          ) : (
            DEFAULT_CURRENCY_CODE
          )
        }
      },
      {
        id: "on_boarding_type",
        label: "Onboarding",
        width: "5%",
        sortable: true,
        renderColumn: rowData => {
          return rowData["on_boarding_type"] ? (
            <span>{rowData["on_boarding_type"]}</span>
          ) : (
            NOT_AVAILABLE
          )
        }
      }
    ]
  )

  const handleSearch = searchTerm => {
    setSearchParam(searchTerm)
  }

  const goBack = () => {
    history.goBack()
  }

  const getCandidateList = useCallback(
    ({ page, limit, sortBy, sortDir, searchTerm }) => {
      setRequestParams({
        sortBy,
        sortDir,
        searchTerm,
        jobid: jobInformation?.id
      })
      pageInfo.current = {
        page,
        limit,
        sortBy,
        sortDir,
        searchTerm,
        applicationStatus: listType
      }
      const targetJobId = jobId ?? jobInformation?.id
      getCandidateListDispatch({
        page,
        limit,
        sortBy,
        sortDir,
        jobId: targetJobId,
        listType,
        searchParam: searchTerm,
        allJobs: !targetJobId
      })
      updateQueryParamsInUrl(pageInfo)
    },
    [getCandidateListDispatch, jobInformation, listType]
  )

  const exportList = () => {
    const candidateIDs =
      Object.keys(selectedCandidates)?.filter(
        id => selectedCandidates[id] === true && !selectedCandidates.all
      ) || []
    exportCandidateListDispatch({
      ...requestParams,
      candidateIDs
    })
  }

  useEffect(() => {
    ;(jobId ?? jobInformation?.id) &&
      getCandidateCount({ jobId: jobId ?? jobInformation?.id, searchParam })
  }, [getCandidateCount, searchParam, jobInformation])

  useEffect(() => {
    return () => getCandidateListSuccessDispatch(false)
  }, [getCandidateListSuccessDispatch])

  useEffect(() => {
    if (bulkUpdateRequesting === false) {
      getCandidateCount({ jobId: jobId ?? jobInformation?.id, searchParam })
      resetSelection()
      refreshCandidateList()
    }
  }, [bulkUpdateRequesting])

  const resetSelection = () => {
    setSelectedCandidates({})
    setSelectedApplications({})
  }

  const refreshCandidateList = () => {
    const { page, limit, sortBy, sortDir, searchTerm } = pageInfo.current
    const data = { page, limit, sortBy, sortDir, searchTerm }
    Object.keys(data).forEach(k => data[k] == null && delete data[k])
    getCandidateList(data)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopUpClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? "application-status-popover" : undefined

  const shouldDisableBulkUpdateButton = () => {
    return !Object.values(selectedApplications).some(x => x)
  }

  return (
    <>
      <section className="candidate-list-section">
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={exportRequesting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="header">
          <div className="back-option" onClick={goBack}>
            <ChevronLeftIcon />
            &nbsp;Back
          </div>
          <div className="heading-div">
            <h3>{jobInformation?.title || "All applications"}</h3>
            <div className="button-wrapper">
              <IconButton
                label="Export"
                endIcon={<ExportIcon />}
                className="export-list border-button"
                onClick={exportList}
              />
            </div>
          </div>
          <USearch
            placeholder="Search for candidates"
            onClick={handleSearch}
            searchRequesting={searchParam && requesting}
            initialValue={searchParam}
          />
          <div className="candidate-bulk-assignment-section">
            <div className="section-title">
              Update application status of selected users:{" "}
            </div>
            <IconButton
              className="border-button profile-action-btn"
              endIcon={<ExpandMoreIcon />}
              onClick={handleClick}
              label={`${listType ?? "Action"}`}
              disabled={shouldDisableBulkUpdateButton()}
              size="small"
            />
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopUpClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
            >
              {jobStatus.slice(1).map(status => {
                return (
                  <div
                    key={status?.key}
                    className="option"
                    onClick={e => {
                      const applicationIds =
                        Object.keys(selectedApplications)?.filter(
                          id => selectedApplications[id] === true
                        ) || []
                      const res = bulkCandidatesStatusUpdateDispatch({
                        applicationIds,
                        status: status?.key
                      })
                      handlePopUpClose()
                    }}
                  >
                    {status?.label}
                  </div>
                )
              })}
            </Popover>
          </div>
          <div className="candidate-tab-section">
            {listTypes
              .filter(item =>
                jobInformation ? true : item?.key === ALL_CANDIDATES_KEY
              )
              .map(item => {
                const count =
                  candidateCount &&
                  candidateCount[`${item?.key?.toLowerCase()}_jobs`]
                return (
                  <div
                    className="candidate-type-tab"
                    onClick={handleTabClick(item?.key)}
                    key={item?.key}
                  >
                    <span
                      className={`text ${
                        listType === item?.key ? "active" : ""
                      }`}
                    >
                      {item?.label}
                      {count >= 0 && `(${count})`}
                    </span>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="candidates-tab-content">
          <div className="candidates-card-section">
            <div className="candidates-list-card">
              <DataTable
                columns={CandidatesListColumns}
                data={
                  isArray(candidateData) ? candidateData : candidateData?.data
                }
                totalPages={
                  isArray(candidateData)
                    ? 0
                    : candidateData?.page?.total_page || 1
                }
                totalRows={isArray(candidateData) ? candidateCount?.length : 0}
                getTableData={getCandidateList}
                loading={requesting}
                key={`${listType}_table`}
                searchTerm={searchParam}
                setSearchTerm={setSearchParam}
                pageInfoInitialValue={pageInfo.current}
              />
            </div>
          </div>
        </div>
      </section>
      <ExportModal
        showExportModal={showExportModal}
        setShowExportModal={setShowExportModal}
      />
    </>
  )
}

const mapStateToProps = state => ({
  googleLoginInfo: state.login.googleLoginInfo,
  candidateData: state.candidateList.candidateData,
  requesting: state.candidateList.requesting,
  candidateCount: state.candidateList.candidateCount,
  exportRequesting: state.candidateList.exportRequesting,
  jobDetails: state.jobDetails.jobInformation,
  bulkUpdateRequesting: state.candidateList.bulkUpdateRequesting
})

const mapDispatchToProps = dispatch => ({
  getCandidateListDispatch: data => dispatch(getCandidateListRequest(data)),
  getCandidateListSuccessDispatch: data =>
    dispatch(getCandidateListSuccess(data)),
  getCandidateCount: data => dispatch(getCandidateCountRequest(data)),
  exportCandidateListDispatch: data =>
    dispatch(exportCandidateListRequest(data)),
  getJobData: data => dispatch(getJobDataByIdRequest(data)),
  bulkCandidatesStatusUpdateDispatch: data =>
    dispatch(bulkCandidatesStatusUpdateRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CandidateList)
